/* src/components/style/Success.css */

.success-page {
  text-align: center;
  margin-top: 50px;
}

.back-button {
  padding: 10px 20px;
  background-color: #4caf50;
  border: none;
  color: white;
  cursor: pointer;
  margin-top: 20px;
}

.back-button:hover {
  background-color: #45a049;
}

.success-page h3 {
    font-size: 20px;
    font-weight: 300;
    margin-top: 20px;
}

.success-page p {
    font-size: 20px;
    font-weight: 600;
    margin-top: 20px;
}