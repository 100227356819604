/* src/components/style/HowItWorks.css */

.how-it-works {
  text-align: center;
  padding: 20px 20px;
}

.how-it-works-title {
  font-size: 1.7em;
  margin-bottom: 20px;
  color: #555555;
  margin-top: 0px;
}

.steps {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: nowrap; /* Prevent wrapping into multiple rows */
  transition: flex-direction 0.3s ease; /* Smooth transition when changing direction */
}

/* Step Box Styling */
.step-box {
  border: 4px solid #1adce7;
  border-radius: 10px;
  width: 190px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
  font-size: 1.1em;
  font-weight: bold;
}

/* Optional: Adjust font-size or padding for better fit */
.step-box p {
  margin: 0;
}

/* Responsive Design */
@media (max-width: 520px) {
  .steps {
    flex-direction: column; /* Stack steps vertically */
    align-items: center;    /* Center align the steps */
  }

  .step-box {
    width: 80%;             /* Adjust width for better visibility */
    width: 160px;       /* Optional: Set a max-width for larger screens */
    /* Remove height: auto; since aspect-ratio handles height */
  }

  /* Remove the bottom margin from the last step */
  .step-box:last-child {
    margin-bottom: 0;
  }
}

/* Optional: Adjust for Medium Screens */
@media (max-width: 800px) and (min-width: 521px) {
  .steps {
    flex-direction: column; /* Also stack vertically for medium screens */
    align-items: center;
  }

  .step-box {
    width: 60%;             /* Adjust width as needed */
    width: 160px;
    /* Remove height: auto; since aspect-ratio handles height */
  }

  .step-box:last-child {
    margin-bottom: 0;
  }
}

/* Optional: Further Adjustments for Very Small Screens */
@media (max-width: 480px) {
  .step-box {
    width: 90%;             /* Increase width for better visibility on very small screens */
    width: 160px;       /* Optional: Set a smaller max-width */
  }

  .how-it-works-title {
    font-size: 1.5em;       /* Slightly smaller title font */
  }

  .how-it-works {
    padding: 15px 15px;     /* Reduce padding for smaller screens */
  }
}