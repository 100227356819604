/* ShareCard.css */

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
  font-size: 1.5em;
  color: #555;
}

.error-message {
  color: red;
  text-align: center;
  margin: 20px;
  font-weight: bold;
}

.rv-details {
    display: flex;
    justify-content: center;
    align-items: center; /* Optional: centers vertically if needed */
    padding: 20px; /* Optional: adds space around the Card */
  }