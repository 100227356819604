/* RVCard.css */

.rv-card {
  border: 1px solid #ccc;
  padding: 16px;
  padding-bottom: 10px;
  margin: 16px;
  text-align: center;
  background-color: white; /* Ensure background color is set */
  width: 100%; /* Ensure the card takes full width of the grid cell */
  max-width: 300px; /* Set maximum width */
  min-width: 200px; /* Set minimum width */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.rv-card h3,
.rv-card h4,
.rv-card h6 {
  margin: 8px 0; /* Reduce the gap between headers */
}

.rv-card p {
  margin: 4px 0 8px 0; /* Reduce the margin to make rows closer together */
  font-size: 0.9em; /* Make the text smaller */
}

/* Add styles for the button */
.rv-card button {
  padding: 10px 20px;
  background-color: #1adce7; /* Use the same blue color */
  border: none; /* Remove the border */
  color: #000000; /* Use a light gray color for better visibility */
  font-size: 1em;
  font-weight: bold; /* Make the text bold */
  cursor: pointer;
  border-radius: 5px; /* Add rounded corners */
  transition: background-color 0.3s ease, color 0.3s ease; /* Add transition for hover effect */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
  margin-top: 10px; /* Add margin above the button */
  margin-bottom: 10px;
}

.rv-card button:hover {
  background-color: #1aa6e7; /* Darker blue on hover */
  color: #ffffff; /* Change text color to white on hover */
}

/* Logo Container Styling */
.logo-container {
  display: flex;
  flex-direction: row;          /* Arrange logos horizontally */
  flex-wrap: nowrap;            /* Prevent wrapping */
  justify-content: space-between;      /* Center logos horizontally */
  align-items: center;          /* Vertically center the logos */
  width: 100%;                  /* Occupy full width of the parent */
  margin-bottom: 10px;          /* Space below the logos */
}

.logo-container button {
  margin: 0px;
  padding: 2px;
  background-color: transparent;
}

.logo-container button:hover {
  background-color: #1adce7; /* Darker blue on hover */
  color: #ffffff; /* Change text color to white on hover */
}

/* Set a fixed height and auto width for the brand logo */
.brand-logo {
  max-width: 100px;
  max-height: 30px !important;             /* Fixed height for consistency */
  width: auto;                   /* Maintain aspect ratio */
  object-fit: contain;           /* Ensure the entire image is visible */
  /* Remove width: 100%; to prevent stretching */
}

.share-button {
  cursor: pointer;
}

.share-icon {
  max-height: 30px !important;             /* Reduced height for smaller appearance */
  width: auto;                   /* Maintain aspect ratio */
  object-fit: contain;           /* Ensure the entire image is visible */
  /* Remove width: 100%; to prevent stretching */
}

.share-message {
  position: absolute;
  top: 60px; /* Adjust based on your layout */
  right: 16px;
  background-color: #1adce7;
  padding: 8px 12px;
  border-radius: 4px;
  color: black;
  font-weight: bold;
  z-index: 1000;
  /* Optional: Add transition for fade-out effect */
  opacity: 0.9;
}

/* Optional: Add spacing between logos if needed */
.logo-container > div:not(:last-child) {
  margin-right: 10px;            /* Space between brand and chassis logos */
}

/* Carousel Wrapper Styling */
.carousel-wrapper {
  width: 100%;
  aspect-ratio: 1.5 / 1; /* Maintains a square aspect ratio */
  position: relative;
  margin: 16px 0; /* Optional: Adds vertical spacing */
}

.carousel-wrapper > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Parent Group Wrapper Styling */
.parent-group-wrapper {
  text-align: left; /* Align text to the left */
  font-size: 0.7em; /* Make the text smaller */
  margin: 0px; /* Set margin to 0 */
}

/* Override margin for p inside parent-group-wrapper */
.parent-group-wrapper p {
  margin: 0; /* Set margin to 0 */
}

.series-name-wrapper {
  margin-top: 8px;
  margin-bottom: 12px;
  font-size: 1.2em;
}

.rv-price-wrapper {
  margin-top: 10px;
  margin-bottom: 0px;
  font-size: 1em;
}