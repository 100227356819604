/* src/components/style/Buy.css */

.buy-page {
  max-width: 600px;
  margin: 0 auto;
  padding: 40px 20px;
}

.buy-page h2 {
  text-align: center;
  font-size: 2em;
  margin-bottom: 10px;
  color: #555555;
}

.sub-title {
    text-align: center;
    font-size: 1em;
    margin-bottom: 30px;
    color: #777777;
  }

.buy-form {
  display: flex;
  flex-direction: column;
}

.section {
  margin-bottom: 30px;
}

.section h3 {
  border-bottom: 2px solid #1adce7;
  padding-bottom: 10px;
  margin-bottom: 20px;
  color: #1adce7;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 8px;
  color: #333333;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 10px 15px;
  border: 1px solid #cccccc;
  border-radius: 5px;
  font-size: 1em;
  color: #333333;
  box-sizing: border-box;
}

fieldset {
  border: none;
}

/* Reduce opacity for disabled fields */
fieldset:disabled .form-group input,
fieldset:disabled .form-group select,
fieldset:disabled .form-group textarea {
  background-color: #f5f5f5;
  cursor: not-allowed;
}

.form-group select {
  background-color: #ffffff;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.form-group select:focus {
  outline: none;
  border-color: #1adce7;
}

.form-group textarea {
  resize: vertical;
  height: 100px;
}

.button-group {
  display: flex;
  gap: 10px;
}

.select-button {
  flex: 1;
  padding: 10px 0;
  background-color: #ffffff;
  border: 2px solid #1adce7;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s ease, color 0.3s ease;
  font-weight: bold;
}

.select-button.active,
.select-button:hover {
  background-color: #1adce7;
  color: #000000;
}

.submit-button {
  padding: 12px 20px;
  background-color: #1adce7;
  border: none;
  border-radius: 5px;
  color: #000000;
  font-size: 1.1em;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #000000;
  color: #ffffff;
}

.cf-captcha {
    padding-top: 20px;
}

p {
    font-weight: bold;
}

/* Responsive Design */
@media (max-width: 600px) {
  .buy-page {
    padding: 20px 15px;
  }

  .submit-button {
    width: 100%;
  }
}

/* Spinner Styles */
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border-left-color: #09f;
  animation: spin 1s linear infinite;
  display: inline-block;
  vertical-align: middle;
  margin-left: 8px;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}