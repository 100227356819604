/* PrivacyPolicy.css */

.privacy-policy {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  line-height: 1.6;
  font-family: Arial, sans-serif;
}

.privacy-policy h1 {
  text-align: center;
  margin-bottom: 20px;
}

.privacy-policy section {
  margin-bottom: 20px;
}

.privacy-policy h2 {
  color: #002c2e;
  margin-bottom: 10px;
}

.privacy-policy ul {
  /* Remove bullet points */
  list-style: none;
  
  /* Optional: Adjust indentation */
  padding-left: 0;
  margin-left: 0;
  
  /* Optional: Add spacing between items */
  /* You can adjust the value as needed */
  gap: 10px;
  
  /* If you want to display items horizontally */
  /* Uncomment the line below */
  /* display: flex; flex-direction: column; */
}

.privacy-policy li {
  /* Optional: Add padding or margin to list items */
  /* For example, to add space between lines */
  margin-bottom: 10px;
}

.privacy-policy a {
  color: #002c2e;
  text-decoration: none;
}

.privacy-policy a:hover {
  text-decoration: underline;
}