.rv-carousel {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.rv-carousel img {
  max-width: 100%;
  max-height: 300px;
}

.rv-carousel button {
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  font-size: 1.5rem;
  padding: 0.5rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.rv-carousel button:first-of-type {
  left: 0;
}

.rv-carousel button:last-of-type {
  right: 0;
}