/* WildcardCard.css */

.wildcard-card {
  border: 2px dashed #ccc;
  border-radius: 8px;
  margin-top: 16px;
  margin-bottom: 16px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #f9f9f9;
  max-width: 300px; /* Set maximum width */
  min-width: 200px; /* Set minimum width */
}

.wildcard-content h4 {
  margin-bottom: 8px;
  color: #333;
}

.wildcard-content p {
  margin-bottom: 16px;
  color: #353535;
}

.wildcard-content button {
  padding: 8px 16px;
  background-color: #1adce7;
  border: none;
  color: black;
  font-weight: bold;
  font-size: 1em;
  cursor: pointer;
  border-radius: 16px;
  transition: background-color 0.3s ease;
}

.wildcard-content button:hover {
  background-color: #1aa6e7;
}