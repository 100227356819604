/* Footer.css */

.App-footer {
  padding-top: 30px;
  padding-bottom: 30px;
  color: rgb(0, 0, 0);
  padding-left:20px;
  padding-right: 20px;
}

.App-footer .footer-content {
  margin-top: 10px;
  display: flex;
  justify-content: center;     /* Center items horizontally */
  align-items: center;
  flex-wrap: wrap;
}

.App-footer p {
  margin: 0;
  margin-right: 20px;          /* Space between items */
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: 400;
}

.App-footer .footer-link {
  color: #000000;
  text-decoration: none;
  margin-left: 15px;           /* Space between links */
  font-size: 16px;             /* Ensure consistent font size */
}

.App-footer .footer-link:hover {
  text-decoration: underline;
}

.App-footer button {
  background-color: #1adce7;
  border: none;
  color: black;
  padding: 10px 20px;
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 30px;
  cursor: pointer;
  border-radius: 5px;
}

.App-footer button:hover {
  background-color: #1aa6e7;
  color: #ffffff;
}