/* src/components/style/Menu.css */

.sidebar {
  height: 100%; /* Full-height */
  width: 0; /* Initial width of 0 */
  position: fixed; /* Stay in place */
  z-index: 1500; /* Above the header */
  top: 0;
  right: 0; /* Slide in from the right */
  background-color: #ffffff; /* Background color */
  overflow-x: hidden; /* Disable horizontal scroll */
  transition: width 0.3s ease; /* Smooth slide-in */
  box-shadow: -2px 0 5px rgba(0,0,0,0.5); /* Optional: Add shadow */
}

.sidebar.open {
  width: 250px; /* Width when open */
}

.close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 2em;
  background: none;
  border: none;
  cursor: pointer;
  color: #555555;
}

.close-button:focus {
  outline: none;
}

.close-button img {
    height: 40px;
}

.sidebar-links {
  display: flex;
  flex-direction: column;
  margin-top: 80px; /* Space below the close button */
  gap: 20px;
  padding: 0 20px;
}

.sidebar-link,
.sidebar-button {
  text-decoration: none;
  color: #555555;
  font-size: 1.2em;
  transition: color 0.3s ease;
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px 0;
  text-align: left;
  width: 100%;
  font-family: inherit; /* Inherit font from parent */
  font-weight: bold;
}

.sidebar-link:hover,
.sidebar-button:hover {
  color: #1adce7;
}

.sidebar-button {
  /* Additional styles if needed */
}

/* Optional: Overlay to dim the rest of the page when sidebar is open */
.overlay {
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0,0,0,0.4);
  z-index: 1400; /* Below the sidebar */
}

.overlay.open {
  display: block;
}

.menu-button {
    /* Always display the menu button */
    display: flex; /* Changed from none to flex */
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    /* Optional: Add hover effects */
    transition: background-color 0.3s ease;
    margin-right: 0px;
  }

  .menu-button img {
    height: 50px;
  }