/* src/components/style/About.css */

.about {
  text-align: center;
  padding: 40px 20px;
}

.about-title {
  font-size: 2em;
  margin-bottom: 30px;
  color: #333333;
}

.about-text {
  font-size: 1em;
  color: #555555;
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.6;
}