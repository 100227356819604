/* TermsAndConditions.css */

.terms-and-conditions {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  line-height: 1.6;
  font-family: Arial, sans-serif;
}

.terms-and-conditions h1 {
  text-align: center;
  margin-bottom: 20px;
}

.terms-and-conditions section {
  margin-bottom: 20px;
}

.terms-and-conditions h2 {
  color: #001e20;
  margin-bottom: 10px;
}

.terms-and-conditions ul {
  list-style: none; /* Removes default bullet points */
  padding-left: 0;
  margin-left: 0;
}

.terms-and-conditions ul li {
  margin-bottom: 10px;
  position: relative;
  padding-left: 15px;
  list-style: none;
}

.terms-and-conditions ul li::before {
  content: "– "; /* Adds a dash before each list item */
  position: absolute;
  left: 0;
  color: #001e20;
}

.terms-and-conditions a {
  color: #001e20;
  text-decoration: none;
}

.terms-and-conditions a:hover {
  text-decoration: underline;
}