/* Modal.css */

/* Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure the modal is on top */
  overflow: auto; /* Enable scrolling if content exceeds viewport */
}

/* Modal Content */
.modal-content {
  background-color: #fff;
  border-radius: 8px;
  width: 90%;
  max-width: 600px; /* Adjust as needed */
  max-height: 90vh; /* Prevent modal from exceeding viewport height */
  overflow-y: auto; /* Enable internal scrolling */
  padding: 20px;
  position: relative;
  box-shadow: 0 5px 15px rgba(0,0,0,0.3);
}

/* Close Button */
.modal-close {
  position: absolute;
  top: 10px;
  right: 15px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #aaa;
  transition: color 0.3s;
}

.modal-close:hover {
  color: #000;
}

/* Optional: Smooth scrolling */
.modal-content::-webkit-scrollbar {
  width: 8px;
}

.modal-content::-webkit-scrollbar-thumb {
  background-color: rgba(0,0,0,0.2);
  border-radius: 4px;
}

.modal-content::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0,0,0,0.4);
}