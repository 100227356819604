/* Grid.css */

/* Align controls horizontally to the left */
.controls-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start; /* Align items to the start */
  width: 100%;
  gap: 16px; /* Space between items */
  padding: 16px; /* Optional: Add padding for better spacing */
  box-sizing: border-box; /* Ensure padding doesn't affect total width */
}

/* Ensure the Filter and Sort containers use only necessary space */
.filter-container,
.sort-container {
  flex: 0 1 auto; /* Prevent growing, allow shrinking */
}

/* Align rv-count to the left */
.rv-count {
  /* Remove flex properties */
  /* flex: 1 1 100%; */
  text-align: left; /* Align text to the left */
  margin: 0; /* Remove default margins */
  font-size: 1em;
  color: #434343;
}

/* Center the rv-count on larger screens if needed */
@media (min-width: 600px) {
  .rv-count {
    text-align: left; /* Ensure text is left-aligned */
  }
}

/* Ensure the RV grid is below the controls */
.rv-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 16px;
  padding: 20px;
  padding-top: 5px;
  justify-items: center;
}

/* Updated Logo Container Styling */
.logo-container {
  display: flex;
  flex-direction: row;             /* Arrange logos horizontally */
  flex-wrap: nowrap;               /* Prevent wrapping */
  justify-content: space-between;  /* Space logos evenly */
  align-items: center;             /* Vertically center the logos */
  width: 100%;                     /* Occupy full width of the parent */
  margin-bottom: 0px;              /* Space below the logos */
}