/* src/components/style/FAQ.css */

.faq {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 30px;
  padding-right: 30px;
}

.faq-title {
  font-size: 1.7em;
  margin-top: 0px;
  margin-bottom: 20px;
  color: #555555;
}

.faq-list {
  max-width: 600px;
  margin: 0 auto;
}

.faq-item {
  border: 2px solid #d3d3d3;
  border-radius: 10px;
  padding: 15px 20px;
  margin-bottom: 15px;
  background-color: transparent;
  transition: background-color 0.3s ease;
}

.faq-item.active {
  background-color: #f9f9f9;
}

.faq-question {
  font-size: 1.2em;
  font-weight: bold;
  color: #555555;
  cursor: pointer;
  position: relative;
  padding-right: 25px; /* Increased padding for better spacing */
  display: flex;
  align-items: center; /* Vertically centers the content */
}

.faq-question::after {
  content: '+';
  position: absolute;
  right: 0;
  top: 50%; /* Positions the '+' at 50% from the top */
  transform: translateY(-50%); /* Centers the '+' vertically */
  font-size: 1.5em;
  transition: transform 0.3s ease;
}

.faq-item.active .faq-question::after {
  transform: translateY(-50%) rotate(45deg); /* Maintains vertical centering while rotating */
}

.faq-answer {
  margin-top: 10px;
  font-size: 1em;
  color: #333333;
  text-align: left;
  animation: fadeIn 0.3s ease;
}

/* Fade-in animation for answers */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

/* Responsive Design */
@media (max-width: 600px) {
  .faq-item {
    padding: 10px 15px;
  }

  .faq-question {
    font-size: 1em;
    padding-right: 20px; /* Adjusted padding for smaller screens */
  }

  .faq-answer {
    font-size: 0.9em;
  }
}