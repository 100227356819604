/* src/components/style/Header.css */

.Header {
  /*background-color: #282c34;*/
  background-color: transparent;
  padding: 10px 30px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.App-logo {
  height: 40px;
  margin-right: 10px;
}

.logo-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #000000;
}

.logo-link h1 {
  margin: 0;
  line-height: normal;
  color: inherit;
  font-size: calc(25px + 2vmin);
  transition: color 0.3s ease;
}

.logo-link:hover {
  color: #1adce7;
}

.logo-link img {
  display: block;
}

.header-right {
  display: flex;
  align-items: center;
  gap: 20px;
}

.about-link {
  color: #000000;
  text-decoration: none;
  font-size: 1em;
  transition: color 0.3s ease;
  font-weight: bold;
}

.about-link:hover {
  color: #1adce7;
}

.contact-button {
  background-color: transparent;
  color: #000000;
  font-size: 1em;
  transition: color 0.3s ease;
  font-weight: bold;
  border: none;
  cursor: pointer;
}

.contact-button:hover {
  color: #1adce7; 
}