.quote-form {
  display: flex;
  flex-direction: column;
}

.quote-form-levels {
  margin-bottom: 10px;
}

.quote-form label {
  margin-right: 10px;
}

.quote-form label {
  margin-right: 10px;
  font-weight: bold; /* Make labels bold */
}

.quote-form input {
  padding: 5px;
  margin-right: 5px;
  font-size: 16px; /* Increase font size */
  border: 1px solid #ccc; /* Set border color to grey */
}

.quote-form button {
  padding: 10px;
  background-color: #1adce8;
  border: none;
  cursor: pointer;
}

.quote-form button:hover {
  background-color: #1aa6e7;
  color: #ffffff;
}

/* Styles for react-select */
.react-select-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
}

.react-select-container {
  width: 250px; /* Adjust width as needed */
  justify-content: center;
  justify-items: center;
}

.react-select__control {
  height: 30px; /* Adjust height as needed */
  min-height: 30px; /* Ensure the minimum height is consistent */
}

.react-select__menu {
  width: 250px; /* Ensure the dropdown menu width matches the control */
}

.react-select__option {
  white-space: nowrap; /* Ensure options are not wrapped */
}

.disclaimer {
  font-size: 0.8em;
  color: #666;
  margin-top: 8px;
  text-align: center;
}

.comments-field {
  flex-direction: column; /* Stack label and textarea vertically */
  align-items: flex-start; /* Align items to the left */
}

.comments-field label {
  margin-bottom: 5px; /* Add space below the label */
}

.comments-field textarea {
  width: 100%;
  margin-right: 0;
  /* Add margin-top to create space between label and textarea */
  margin-top: 5px;
}

/* Apply consistent styles to both inputs and textareas */
.quote-form input,
.quote-form textarea {
  padding: 5px;
  margin-right: 5px;
  font-size: 16px; /* Ensure consistent font size */
  border: 1px solid #ccc;
  font-family: inherit; /* Inherit font family from parent */
  box-sizing: border-box; /* Ensure padding and border are included in the total width and height */
}
