/* src/components/style/MainBanner.css */

/* Container for the banner */
.main-banner {
  position: relative;
  width: 100%;
  padding: 20px 20px;
  margin-bottom: 90px;
  background-color: transparent; /* Removed background color */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: background-color 0.3s ease;
}

/* Overlay to hold content */
.banner-overlay {
  position: relative; /* Positioned above any background */
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center vertically */
  align-items: center;     /* Center horizontally */
}

/* Title styling */
.banner-title {
  font-size: 4.5em;
  font-weight: normal;
  margin-bottom: 20px;
  color: #000000; /* Text color */
  line-height: 1.2; /* Improve readability */
  word-break: break-word; /* Prevent long words from overflowing */
  padding-left: 15px;
  padding-right: 15px;
}

/* Tagline styling */
.tagline {
  font-size: 19px;
  color: #726f6f;          /* Set text color */
  padding-top: 15px;       /* Add padding on top */
  white-space: nowrap;
  font-style: italic;
  margin-bottom: 70px;
  text-align: center;      /* Ensure centered text */
  padding-left: 20px;
  padding-right: 20px;
}

/* Container for the buttons */
.banner-buttons {
  display: flex;
  gap: 20px; /* Space between buttons */
}

/* Common button styling matching BuySell.css's .action-box button */
.action-button {
  width: 200px;              /* Set button width */
  padding: 20px 20px;
  background-color: #1adce7; /* Blue color from BuySell.css */
  border: none;              /* Remove default button border */
  color: #000000;            /* Black text color for visibility */
  font-size: 1.3em;
  font-weight: bold;         /* Bold text */
  cursor: pointer;
  border-radius: 15px;       /* Rounded corners */
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transitions */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  text-align: center;        /* Center text inside buttons */
}

/* Hover effects for both buttons */
.action-button:hover {
  background-color: #000000; /* Darker blue on hover */
  color: #ffffff;            /* White text on hover */
}

/* Active state for buttons */
.action-button:active {
  transform: translateY(0); /* No lift effect */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Enhanced shadow on click */
}

/* Responsive Design */
@media (max-width: 1024px) {
  .banner-title {
    font-size: 4.25em;
  }
}

@media (max-width: 900px) {
  .banner-title {
    font-size: 4em;
  }
}

@media (max-width: 768px) {
  .main-banner {
    height: auto; /* Allow banner to adjust height based on content */
    padding: 15px 15px; /* Reduce padding for smaller screens */
  }

  .banner-overlay {
    justify-content: center;
  }

  .banner-title {
    font-size: 3.25em; /* Adjust to smaller size */
  }

  .banner-buttons {

    gap: 15px; /* Adjust gap */
  }

  .action-button {
    width: 200px; /* Smaller button width */
    padding: 15px 15px; /* Reduce padding */
    font-size: 1.2em; /* Decrease font size */
  }

  .tagline {
    white-space: normal;   /* Allow tagline to wrap on smaller screens */
    padding-top: 10px;     /* Adjust padding for smaller screens */
    text-align: center;    /* Center the tagline text */
    font-size: 18px;       /* Slightly smaller tagline */
    margin-bottom: 50px;   /* Reduce bottom margin */
  }
}

@media (max-width: 480px) {
  .main-banner {
    margin-bottom: 60px;
  }
  .banner-title {
    font-size: 3em; /* Further reduce font size */
  }

  .action-button {
    width: 180px; /* Further reduce button width */
    padding: 12px 12px; /* Further reduce padding */
    font-size: 1.1em; /* Standardize font size */
  }

  .tagline {
    font-size: 17px;       /* Further reduce tagline font size */
    margin-bottom: 40px;   /* Further reduce bottom margin */
  }

  .banner-buttons {
    flex-direction: column;
  }
}

/* Accessibility: Focus State */
.action-button:focus {
  outline: 2px solid #000000;
  outline-offset: 2px;
}