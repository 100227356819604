/* src/components/style/Recents.css */

.recents-container {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 0px;
}

.recents-title {
  font-size: 1.7em;
  margin-top: 0;
  margin-bottom: 10px;
  color: #555555;
}

/* Carousel layout */
.carousel {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 30px;
  padding-right: 30px;
}

.carousel-wrapper {
  overflow: hidden;
  width: 100%;
  height: 250px;
}

.carousel-items {
  display: flex;
  gap: 20px; /* Maintain desired gap between items */
  padding-left: 20px; /* Optional: space before the first item */
}

.carousel-item {
  flex: 0 0 33.3333%; /* Default for 3 per view */
  max-width: 250px;
  background-color: #f8f8f8;
  border-radius: 8px;
  padding: 20px;
  box-sizing: border-box;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Distribute child elements evenly vertically */
  align-items: flex-start;
  overflow: hidden; /* Ensure content does not overflow */
}

.carousel-item h3 {
  text-align: center;
  font-size: 0.9em;
  font-weight: 600;
  margin: 0; /* Reset margin */
}

.carousel-item img {
  width: 100%;
  height: auto;
  border-radius: 4px;
  object-fit:contain;
  max-height: 150px;
  margin: 10px 0; /* Adds vertical spacing around the image */
  flex-grow: 1; /* Allow the image to grow and take up available space */
}

.carousel-item p {
  font-size: 0.9em;
  color: #1adce7;
  background-color: black;
  border-radius: 5px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 8px;
  padding-right: 8px;
  margin: 0; /* Reset margin */
  margin-top: auto; /* Pushes the p to the bottom */
  flex-grow: 0; /* Do not grow */
  text-align: center;
  font-weight: bold;
}

/* Responsive */
@media (max-width: 900px) {
  .carousel-item {
    flex: 0 0 45%; /* For 2 per view */
    max-width: 230px;
  }
}

@media (max-width: 600px) {
  .carousel-item {
    flex: 0 0 90%; /* For 1 per view */
    max-width: 210px;
  }
}

/* Keyframes for seamless scroll over 4 sets */
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    /* Move left by 3/4 => 75% of the width,
       so the 4th copy lines up exactly with the first. */
    transform: translateX(-75%);
  }
}