/* Filter.css */

/* Apply box-sizing globally */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Container for the entire filter component */
.filters-modal {
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 600px;
  max-height: 90vh;
  background-color: #fff;
  border-radius: 8px;
  position: relative;
}

/* Sticky Header */
.modal-header {
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  background-color: #ffffff;
  padding: 16px;
  border-bottom: 1px solid #ccc;
  z-index: 10;
}

.modal-header h2 {
  margin: 0;
  font-size: 1em;
  color: #333;
}

/* Close Button */
.close-button {
  position: absolute;
  right: 16px;
  top: 16px;
  background: none;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
  color: #333;
}

/* Scrollable Content */
.modal-content {
  flex: 1;
  overflow-y: auto; /* Change to 'scroll' if preferred */
  padding: 16px 16px 16px 16px;
  width: 100%;
}

/* Specific Styles for Number Input Filters */
.filter-number {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 16px; /* Space between title and inputs */
  margin-bottom: 16px; /* Space below each filter section */
}

/* Sticky Footer */
.modal-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  bottom: 0;
  background-color: #ffffff;
  padding: 16px;
  border-top: 1px solid #ccc;
  z-index: 10;
}

/* Style the filter button to align items horizontally */
.filter-button {
  display: flex;
  align-items: center;
  gap: 8px; /* Space between icon and text */
  min-height: 45px;
  padding: 8px 12px;
  background-color: #1adce7;
  border: none;
  color: black;
  font-weight: bold;
  font-size: 1em;
  cursor: pointer;
  border-radius: 16px;
  transition: background-color 0.3s ease;
}
.filter-button:hover {
  background-color: #1aa6e7;
}

/* Style the filter icon */
.filter-icon {
  width: 20px; /* Adjust size as needed */
  height: 20px;
}

/* Apply Button */
.apply-button {
  min-height: 45px;
  padding: 8px 16px;
  background-color: #1adce7;
  border: none;
  color: black;
  font-weight: bold;
  font-size: 1em;
  cursor: pointer;
  border-radius: 16px;
  transition: background-color 0.3s ease;
}

.apply-button:hover {
  background-color: #1aa6e7;
  color: #ffffff;
}

/* Reset (Clear All) Button */
.reset-button {
  min-height: 45px;
  padding: 8px 16px;
  background-color: #ffffff;
  border: none;
  color: black;
  font-weight: bold;
  font-size: 1em;
  cursor: pointer;
  border-radius: 16px;
  transition: background-color 0.3s ease;
}

.reset-button:hover {
  background-color: #ddd;
  color: #000000;
}

/* Container for filter input fields */
.filter-inputs {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 10px;
  justify-content: center;
}

/* Style for input fields */
.filter-inputs input {
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 16px;
  font-size: 1em;
  width: 120px;
}

.filter-inputs input::placeholder {
  color: #999;
}

/* Container for checkbox groups */
.checkbox-group {
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.checkbox-group h4 {
  margin: 8px 0;
  font-size: 1.1em;
  color: #333;
}

.checkbox-options {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.checkbox-label {
  display: flex;
  align-items: center;
  font-size: 0.9em;
  color: #555;
}

.checkbox-label input {
  margin-right: 6px;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .filters-modal {
    width: 95%;
  }

  .filter-inputs input {
    width: 100px;
  }

  .modal-header {
    padding: 12px;
  }

  .close-button {
    right: 12px;
    top: 12px;
  }

  .filter-inputs input {
    width: 100px;
  }
}

@media (max-width: 600px) {
  .filter-inputs {
    flex-direction: column;
    align-items: center;
  }

  .filter-inputs input {
    width: 100%;
    max-width: 300px;
  }

  /* Adjust modal footer buttons for smaller screens */
  .modal-footer {
    flex-direction: column;
    gap: 10px;
    align-items: stretch;
  }

  .reset-button,
  .apply-button {
    width: 100%;
    max-width: 300px;
  }

  /* Close Button Positioning */
  .close-button {
    top: 8px;
    right: 8px;
  }
}