.sort-options {
  padding: 8px 12px;
  background-color: #1adce7; /* Apply blue background */
  color: black;
  font-weight: bold;
  border-radius: 16px; /* Apply rounded edges */
  font-size: 1em;
  display: inline-flex;
  align-items: center;
}

.sort-options label {
  display: flex;
  align-items: center;
}

.sort-options select {
  margin-left: 8px;
  padding: 4px 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1em;
  background-color: #b9f0ff;
  color: black;
  font-weight: bold;
}